import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import UpdatePassword from "./UpdatePassword";
import DeleteAccount from "./DeleteAccount";
import { GetAccountDetails, UpdateAccount, clacAcRes } from "../../../../../Reducer/UserDashboardSlices/userAccountSlice";
import Swal from 'sweetalert2';


export default function AccSettings() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open1, setOpn1] = useState(false);
    const [open2, setOpn2] = useState(false);
    const [email, seEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidMobile, setIsValidMobile] = useState(true);
    const auth = useSelector(state => state.auth.auth);
    const success = useSelector(state => state.userAccount.success);
    const error = useSelector(state => state.userAccount.error);
    const [data, setData] = useState(null);

    useEffect(() => {
        getData();

    }, []);

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clacAcRes());
        } else {
            Swal.fire({
                title: 'Congratulation!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clacAcRes());
        }
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clacAcRes());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clacAcRes());

        }
    }

    async function getData() {
        let datasss = { uid: auth._id };
        let res = await dispatch(GetAccountDetails(datasss));
        if (res.payload.data.data) {
            setData(res.payload.data.data);
            seEmail(res.payload.data.data.email);
            setMobile(res.payload.data.data.mobile);
        }
    }

    const openPwdModal = () => {
        setOpn1(true);
    }
    const openPwdModa2 = () => {
        setOpn2(true);
    }

    const update = (type) => {
        
        if (type === "Email") {
            if (isValidEmail) {
                let up = { uid: auth._id, email: email, type: type };
                console.log('Email updated:', email);
                dispatch(UpdateAccount(up));
            } else {
                alert('Invalid email format!');
            }
        } else {
            if (isValidMobile) {
                let up = { uid: auth._id, mobile: mobile, type: type };
                dispatch(UpdateAccount(up));
            }else{
                alert('Invalid mobile format!');
            }
        }
    }

    const changeEmail = (e) => {
        const newEmail = e.target.value;
        seEmail(newEmail);
        // Perform email format validation here
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(newEmail));
    }

    const changeMobile = (e) => {
        const newMobile = e.target.value;
        const mobileRegex = /^[0-9]{10}$/; // Assuming 10-digit mobile number format
        const isValidMobile = mobileRegex.test(newMobile); // Checking if the email matches the mobile number pattern
       if(isValidMobile){
        setMobile(newMobile)
       }
        setIsValidMobile(isValidMobile);
    }

    return (
        <>
            <div class="set">
                <h2><img src="images/acc-sett.png" alt="" />Account Setting</h2>
                <div class="row">

                    <div class="col-xl-6">
                        <div class="form-group">
                            <label class="form-label">Email ID</label>
                            <input type="email" class="form-control" defaultValue={email} name="emailll" onChange={changeEmail} />
                            <span onClick={() => update("Email")}>Update Email</span>
                        </div>
                    </div>

                    <div class="col-xl-6">
                        <div class="form-group">
                            <label class="form-label">Password</label>
                            <input type="password" class="form-control" />
                            <span onClick={() => openPwdModal()}>Update Password</span>
                        </div>
                    </div>

                    <div class="col-xl-6">
                        <div class="form-group">
                            <label class="form-label">Phone Number</label>
                            <input type="tel" class="form-control" defaultValue={mobile} name="phonneee" onChange={changeMobile} />
                            <span onClick={() => update("Phone")}>Update Phone</span>
                        </div>
                    </div>

                    <div class="col-12">
                        <h3 onClick={() => openPwdModa2()}>
                            <i class="bi bi-trash3"></i>Delete
                            My Account <span>Permanently remove your profile and
                                associated data from the platform.</span></h3>
                    </div>

                </div>
            </div>

            {open1 === true &&
                <UpdatePassword setOpn1={setOpn1} />
            }
            {
                open2 === true &&
                <DeleteAccount setOpn2={setOpn2} />
            }


        </>
    )

}