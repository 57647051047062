import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { heightData, MotherTounge, healthData, Marital, languages } from "../../Data/content";
import Select from 'react-select';
import {
    CitySelect,
    CountrySelect,
    StateSelect,

} from "react-country-state-city";
import Swal from 'sweetalert2';
import "react-country-state-city/dist/react-country-state-city.css";
import { GetBasic, SaveBasic, clearState } from "../../../../Reducer/StepForms/basicSlice";
import { clearUiserUpdateinfoForm,SubmitUiserUpdateinfoForm } from "../../../../Reducer/FrontendForms/updateUserDataSlice";
export default function Basic() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [basic, setbasic] = useState({});
    const [genderSelected, setGenderSelected] = useState(false); // State to track whether gender is selected
    const [nameSelected, setNameSelected] = useState(false); // State to track whether gender is selected
    const [motherToungeSelected, setMotherToungeSelected] = useState(false); // State to track whether gender is selected
    const error = useSelector(state => state.basic.error);
    const success = useSelector(state => state.basic.success);
    const auth = useSelector(state => state.auth.auth);
    const [countryId, setCountryId] = useState(null);
    const [CountryName, setCountry] = useState(null);
    const [stateId, setstateId] = useState(null);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const options = languages.map(language => ({ value: language, label: language }));
    const [errors, setFormErrors] = useState({});
    const data = useSelector(state => state.user.data);
    const [msg, setMsg] = useState("");
    const [btnst, setBtn] = useState(false);
    const [submitType, setSubmitType] = useState("");


    useEffect(() => {

        getData();


    }, []);


    async function getData() {
        let dat = { uid: auth._id };
        let res = await dispatch(GetBasic(dat));
        if (res.payload && res.payload.data.data) {
            if (data && data.profile_status == "Approved") {
                setGenderSelected(!!res.payload.data.data.gender);
                setNameSelected(!!res.payload.data.data.name);
                setMotherToungeSelected(!!res.payload.data.data.Mother_tongue);
            }
            setbasic(res.payload.data.data);
            setSelectedLanguages(res.payload.data.data.languages);
            setCountry(res.payload.data.data.fullCountry);
            setState(res.payload.data.data.fullState);
            setCity(res.payload.data.data.fullCity);
        }
    }

    const Next = () => {
        alert("dsfdsfdsfdsf")
        console.log('data data data data data data data data data', data);
        // if (data2 && data2.basic === false) {
        //     navigate("/UserStepForms", { state: "Basic" });
        // }
        // else if (data2 && data2.astro === false) {
        //     navigate("/UserStepForms", { state: "Astro" });
        // }
        // else if (data2 && data2.families === false) {
        //     navigate("/UserStepForms", { state: "Family" });
        // }
        // else if (data2 && data2.carrers === false) {
        //     navigate("/UserStepForms", { state: "Career" });
        // }
        // else if (data2 && data2.spirituals === false) {
        //     navigate("/UserStepForms", { state: "Spiritual" });
        // }
        // else if (data2 && data2.preferences === false) {
        //     navigate("/UserStepForms", { state: "Preferences" });
        // }
        // else if (data2 && data2.personalityquestions === false) {
        //     navigate("/PersonalityQuestions");
        // }
        // else if (data2 && data2.about === false) {
        //     navigate("/AboutPersonality");
        // }
        // else if (data2 && data2.gallery === false) {
        //     navigate("/UploadGallery");
        // }
        // else if (data2 && data2.selfy === false) {
        //     navigate("/SelfiVerification");
        // }
        // else if (data2 && data2.govtVerification === false) {
        //     navigate("/IdVerification");
        // }
        // else if (data2 && !data2.Personalized_Matchmaking_Choices) {
        //     navigate("/MatchmakingSelection");
        // } else {
        //     navigate("/Dashboard");
        // }

    }


    const handleLanguageChange = (selectedOptions) => {
        setSelectedLanguages(selectedOptions);
    };

    async function handleSubmit(e) {
        setMsg("");
        e.preventDefault();
        let daatSave = { 'uid': auth._id, 'name': basic && basic.name ? basic.name : "", 'gender': basic && basic.gender ? basic.gender : "Male", 'Marital_status': basic && basic.Marital_status ? basic.Marital_status : "Unmarried", 'Mother_tongue': basic && basic.Mother_tongue ? basic.Mother_tongue : "Aka", 'height': basic && basic.height ? basic.height : "", 'weight': basic && basic.weight ? basic.weight : "", 'health': basic && basic.health ? basic.health : "No Health Problems", 'Disability': basic && basic.Disability ? basic.Disability : "None", 'Disease': basic && basic.Disease ? basic.Disease : "", 'fullCountry': CountryName ? CountryName : "", 'fullState': state ? state : "", 'fullCity': city ? city : "", 'languages': selectedLanguages && selectedLanguages.length > 0 && JSON.stringify(selectedLanguages) }

        if (e.nativeEvent.submitter.name === 'draft') {
            setSubmitType("draft");
            daatSave.draft = "true";
            dispatch(SaveBasic(daatSave));
        } else {
            setSubmitType("next");
            let values = validate(basic);
            let chk = true;
            if (Object.keys(values).length > 0) {
                chk = false;
            }
            if (chk) {

                daatSave.draft = "false";
                daatSave.saved = true;
                dispatch(SaveBasic(daatSave));

            } else {
                setFormErrors(validate(basic));
            }

        }

    };

    const validate = (values) => {
        let error = {};

        if (!values.name) {
            error.name = "This field is required!";
        }

        if (!values.gender || (values.gender.trim() === "" && values.gender !== "Male")) {
            errors.gender = "This field is required!";
        }
        if (!values.weight) {
            error.weight = "This field is required!";
        }
        if (!values.height) {
            error.height = "This field is required!";
        }
        if (!values.Marital_status || (values.Marital_status.trim() === "" && values.Marital_status !== "Unmarried")) {
            errors.Marital_status = "This field is required!";
        }
        if (!values.Mother_tongue || (values.Mother_tongue.trim() === "" && values.Mother_tongue !== "Aka")) {
            errors.Mother_tongue = "This field is required!";
        }

        if (!values.health || (values.health.trim() === "" && values.health !== "No Health Problems")) {
            errors.health = "This field is required!";
        }

        if (!values.Disability || (values.Disability.trim() === "" && values.Disability !== "None")) {
            errors.Disability = "This field is required!";
        }

        if (CountryName == null) {
            error.fullCountry = "This field is required!";
        }
        if (state == null) {
            error.fullState = "This field is required!";
        }
        if (city == null) {
            error.fullCity = "This field is required!";
        } if (selectedLanguages && selectedLanguages.length === 0) {
            error.languages = "This field is required!";
        }
        if (
            (values.health === "Other diseases" || values.Disability === "Physical Disability") &&
            !values.Disease
        ) {
            error.Disease = "This field is required!";
        }
        return error;
    }

    const navigationn = () => {
        setMsg("");
        let name = "Astro";
        navigate("/UserStepForms", { state: { name } });
    }



    useEffect(() => {
        if (success && success.status === 200) {
            console.log('testinggggg', success);
            if (success.data.data) {
                dispatch(clearState());
            } else {
                if (submitType === "draft") {
                    setMsg(success.data.message);
                } else if(submitType === "next") {
                    dispatch(clearState());
                    navigationn();
                }
            }
        }
    }, [success]);

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearState());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearState());
                break;
        }
    }

    const handleChangeInput = (e) => {
        if (e.target.name === "name" && data && data.profile_status === "Approved") {
            alert("This edit needs admin approval");

        } else if (e.target.name === "gender" && data && data.profile_status === "Approved") {
            let formData = new FormData();
            formData.append('uid', auth._id);
            formData.append('gender', e.target.value);
           
            dispatch(SubmitUiserUpdateinfoForm(formData));
            alert("This edit needs admin approval");

        } else if (e.target.name === "Mother_tongue" && data && data.profile_status === "Approved") {
            let formData = new FormData();
            formData.append('uid', auth._id);
            formData.append('Mother_tongue', e.target.value);
           
            dispatch(SubmitUiserUpdateinfoForm(formData));
            alert("This edit needs admin approval");
        } else {
            const value = e.target.value;
            setbasic({
                ...basic,
                [e.target.name]: value
            });
        }

    }


    return (
        <>

            <form onSubmit={handleSubmit}>
                <div class="tab">
                    <h3>Basic Details</h3>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Name*</label>
                                <input type="text" class="form-control" name="name" value={basic && basic.name && basic.name} onChange={handleChangeInput} />
                                {/* disabled={nameSelected} */}
                                {errors.name && <p className={"error__feedback"}>{errors.name}</p>}
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Gender*</label>
                                <select class="form-select" aria-label="Default select example" name="gender" onChange={handleChangeInput}  >
                                    {/* <option selected disabled>Select</option> disabled={genderSelected} */}
                                    <option selected={basic && basic.gender && basic.gender === "Male"} value="Male">Male</option>
                                    <option selected={basic && basic.gender && basic.gender === "Female"} value="Female">Female</option>
                                    <option selected={basic && basic.gender && basic.gender === "Other"} value="Other">Other</option>
                                </select>
                                {errors.gender && <p className={"error__feedback"}>{errors.gender}</p>}
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Marital Status*</label>
                                        <select class="form-select" aria-label="Default select example" name="Marital_status" onChange={handleChangeInput}>
                                            {/* <option selected disabled>Select</option> */}
                                            {Marital.length > 0 && Marital.map((test, i) => (
                                                <option value={test} selected={basic && basic.Marital_status && basic.Marital_status === test} >{test}</option>
                                            ))}
                                        </select>
                                        {errors.Marital_status && <p className={"error__feedback"}>{errors.Marital_status}</p>}

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Mother Tongue*</label>
                                        <select class="form-select" aria-label="Default select example" name="Mother_tongue" onChange={handleChangeInput} >
                                            {/* <option selected disabled>Select</option> disabled={motherToungeSelected} */}
                                            {MotherTounge.length > 0 && MotherTounge.map((tem, i) => (
                                                <option selected={basic && basic.Mother_tongue && basic.Mother_tongue === tem} value={tem}>{tem}</option>
                                            ))}
                                        </select>
                                        {errors.Mother_tongue && <p className={"error__feedback"}>{errors.Mother_tongue}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Languages Known*</label>
                                <Select
                                    class="form-select"
                                    options={options}
                                    isMulti
                                    value={selectedLanguages}
                                    onChange={handleLanguageChange}

                                />
                                {errors.languages && <p className={"error__feedback"}>{errors.languages}</p>}

                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="form-label">Height*</label>
                                        <select class="form-select" aria-label="Default select example" name="height" onChange={handleChangeInput}>
                                            <option selected disabled>Select</option>
                                            {heightData.length > 0 && heightData.map((it, i) => (
                                                <option selected={basic && basic.height && basic.height === it.value} value={it.value}>{it.item}</option>

                                            ))}

                                        </select>
                                        {errors.height && <p className={"error__feedback"}>{errors.height}</p>}
                                    </div>

                                </div>

                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="form-label">Weight*</label>
                                        <input type="number" class="form-control" name="weight" value={basic && basic.weight && basic.weight} onChange={handleChangeInput} min="1" max="999" />
                                        <span>kg</span>
                                        {errors.weight && <p className={"error__feedback"}>{errors.weight}</p>}
                                    </div>
                                </div>

                            </div>
                        </div>



                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Physical Status*</label>
                                <select class="form-select" aria-label="Default select example" name="health" onChange={handleChangeInput}>
                                    {/* <option selected disabled>Select</option> */}
                                    {healthData.length > 0 && healthData.map((dd, i) => (
                                        <option selected={basic && basic.health && basic.health === dd} value={dd}>{dd}</option>
                                    ))}

                                </select>
                                {errors.health && <p className={"error__feedback"}>{errors.health}</p>}
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Any Disability*</label>
                                <select class="form-select" aria-label="Default select example" name="Disability" onChange={handleChangeInput}>
                                    {/* <option selected disabled>Select</option> */}
                                    <option selected={basic && basic.Disability && basic.Disability === "None"} value="None">None</option>
                                    <option selected={basic && basic.Disability && basic.Disability === "Physical Disability"} value="Physical Disability">Physical Disability</option>

                                </select>
                                {errors.Disability && <p className={"error__feedback"}>{errors.Disability}</p>}

                            </div>
                        </div>


                        {basic && ((basic.health && basic.health === "Other diseases") || (basic.Disability && basic.Disability === "Physical Disability")) &&
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Details of Disability or other Disease*</label>
                                            <input type="text" class="form-control" name="Disease" defaultValue={basic && basic.Disease && basic.Disease} onChange={handleChangeInput} spellCheck={true} />
                                            {errors.Disease && <p className={"error__feedback"}>{errors.Disease}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }


                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Country*</label>

                                        <CountrySelect
                                            showFlag={false}
                                            inputClassName="form-control"
                                            class="form-select ps-5"
                                            onChange={(e) => {
                                                if (e && e.id && e.name) {

                                                    setCountryId(e.id);
                                                    setCountry(e);
                                                    setstateId(null);
                                                    setCity(null);
                                                    setState(null);

                                                    console.log('setState', state);
                                                }
                                            }}

                                            defaultValue={CountryName}
                                            placeHolder="Select Country"
                                            name="fullCountry"

                                        />
                                        {errors.fullCountry && <p className={"error__feedback"}>{errors.fullCountry}</p>}


                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Current State*</label>
                                        <StateSelect
                                            showFlag={false}
                                            inputClassName="form-control"

                                            countryid={CountryName && CountryName.id ? CountryName.id : countryId}
                                            onChange={(e) => {
                                                console.log('eeeee', e);
                                                if (e && e.id && e.name) {
                                                    setstateId(e.id);
                                                    setState(e);
                                                    setCity("");
                                                } else {
                                                    console.log('else ');
                                                    setState(null)
                                                }
                                            }}
                                            defaultValue={state}
                                            placeHolder="Select State"

                                            name="fullState"
                                        />
                                        {errors.fullState && <p className={"error__feedback"}>{errors.fullState}</p>}

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Current City*</label>

                                <CitySelect
                                    showFlag={false}
                                    inputClassName="form-control"

                                    countryid={CountryName && CountryName.id ? CountryName.id : countryId}
                                    stateid={state && state.id ? state.id : stateId}

                                    onChange={(e) => {

                                        if (e && e.id && e.name) {
                                            console.log(e);
                                            setCity(e)
                                        } else {
                                            setCity(null)
                                        }
                                    }}
                                    defaultValue={city}
                                    placeHolder="Select City"
                                    name="fullCity"

                                />
                                {errors.fullCity && <p className={"error__feedback"}>{errors.fullCity}</p>}
                            </div>
                        </div>

                    </div>
                </div>
                <div class="step_common_btn">
                    <div class="row">
                        <div class="col-sm-6" >
                            <button class="btn two" type="submit" name="draft" disabled={basic && basic.saved && basic.saved === true}>Save Draft</button>

                        </div>

                        <div class="col-sm-6 text-end">
                            <a class="btn" onClick={() => navigationn()}>Skip</a>

                            <button class="btn" type="submit"
                            >Next</button>
                        </div>

                        {msg !== "" && <p class="SucessColor">{msg}</p>}

                    </div>

                </div>
            </form>

        </>
    )

}

