import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "../UserDashboard/Sidebar";
import Swal from 'sweetalert2';
import { GetSavedPrivacy, SaveSetting, clearPvcy } from "../../../Reducer/StepForms/privacySlice";
import { privacyOptions } from "../Data/content";
import { UserDetail } from "../../../Reducer/UserDashboardSlices/userSlice";
import { GetSpritualInfo, SaveSpritualInfo, clearspritualState } from "../../../Reducer/StepForms/spritualSlice";


export default function PrivacyStep() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const success = useSelector(state => state.prvcy.success);
    const error = useSelector(state => state.prvcy.error);
    const [data, setData] = useState({
        basic_privacy: "Everyone",
        family_privacy: "Everyone",
        astro_privacy: "My connection",
        carrer_privacy: "Everyone",
        spritual_privacy: "Everyone",
        personality_privacy: "Everyone",
        photo_privacy: "Everyone",
        contact_privacy: "Everyone",
        activity_status: "Everyone",
        email_privacy: "My connection",
        mobile_privacy: "My connection",
        two_factor_authentication: false,
    });
    const [errors, setFormErrors] = useState({});
    const [msg, setMsg] = useState("");
    const [btnst, setBtn] = useState(false);
    const [sptdata, setsptdata] = useState(null);
    const data2 = useSelector(state => state.user.data);


    useEffect(()=>{
        getDataforSipritual()
    },[])
   const  getDataforSipritual =async ()=>{
    
    let dat = { uid: auth._id };
    let res = await dispatch(GetSpritualInfo(dat));
    if (res.payload && res.payload.data.data) {
        setsptdata(res.payload.data.data)
    }
   }
    if (success && success.status === 200) {
        if (success.data.data) {

            dispatch(clearPvcy());
        } else {
            // Swal.fire({
            //     title: 'Congratulation!',
            //     text: success.data.message,
            //     icon: 'success'
            // });
            setMsg(success.data.message);
            setBtn(true);
            dispatch(clearPvcy());
            // setTimeout(() => {
            //     navigate("/FinalStep");
            // }, 2000);
        }
    }
    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearPvcy());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearPvcy());

        }
    }
    const skip=()=>{
        const formcheck = sptdata.affiliated_with_spiritual_organization === "ISKCON Bangalore & HKM Centers" &&
        sptdata.iskcon_associted_with === "ISKCON Temple, Bengaluru, Karnataka (Sri Sri Radha Krishnachandra Temple)" &&
                  (sptdata.temple_associated_type === "FOLK" || sptdata.temple_associated_type === "Saadhika");
        
                  if(!formcheck && data2.selected_plan == null){
                    navigate('/SelectPlans');
                }
                else if (data2.selected_plan == null && formcheck) {
                    navigate("/MatchmakingSelection");
                }else {
                    navigate("/Dashboard");
                }
    }
    const Next = () => {
        const formcheck = sptdata.affiliated_with_spiritual_organization === "ISKCON Bangalore & HKM Centers" &&
        sptdata.iskcon_associted_with === "ISKCON Temple, Bengaluru, Karnataka (Sri Sri Radha Krishnachandra Temple)" &&
                  (sptdata.temple_associated_type === "FOLK" || sptdata.temple_associated_type === "Saadhika");
        

        if(!formcheck && data2.selected_plan == null){
            navigate('/SelectPlans');
        }
        else if (data2.selected_plan == null && formcheck) {
            navigate("/MatchmakingSelection");
        } else {


            if (data2 && data2.basic === false) {
                navigate("/UserStepForms", { state: "Basic" });
            }
            else if (data2 && data2.astro === false) {
                navigate("/UserStepForms", { state: "Astro" });
            }
            else if (data2 && data2.families === false) {
                navigate("/UserStepForms", { state: "Family" });
            }
            else if (data2 && data2.carrers === false) {
                navigate("/UserStepForms", { state: "Career" });
            }
            else if (data2 && data2.spirituals === false) {
                navigate("/UserStepForms", { state: "Spiritual" });
            }
            else if (data2 && data2.preferences === false) {
                navigate("/UserStepForms", { state: "Preferences" });
            }
            else if (data2 && data2.personalityquestions === false) {
                navigate("/PersonalityQuestions");
            }
            else if (data2 && data2.about === false) {
                navigate("/AboutPersonality");
            }
            else if (data2 && data2.gallery === false) {
                navigate("/UploadGallery");
            }
            else if (data2 && data2.selfy === false) {
                navigate("/SelfiVerification");
            }
            else if (data2 && data2.govtVerification === false) {
                navigate("/IdVerification");
            }
            else if (data2 && !data2.Personalized_Matchmaking_Choices) {
                navigate("/MatchmakingSelection");
            } else {
                navigate("/Dashboard");
            }
        }
    }

    useEffect(() => {
        let datasss = { uid: auth._id };
        dispatch(UserDetail(datasss));
        getData();

    }, []);

    async function getData() {
        let dat = { uid: auth._id };
        let res = await dispatch(GetSavedPrivacy(dat));
        if (res.payload && res.payload.data.data) {
            const responseData = res.payload.data.data;
            if (Object.keys(responseData).length > 0) {
                console.log('responseData', res.payload.data.data);
                setData(responseData);
            }

        }
    }

    const handleChangeInput = (e) => {
        if (e.target.name === "two_factor_authentication") {
            const value = e.target.checked;
            setData({
                ...data,
                [e.target.name]: value
            });
        } else {
            const value = e.target.value;
            setData({
                ...data,
                [e.target.name]: value
            });
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let values = validate(data);
        let chk = true;
        if (Object.keys(values).length > 0) {
            chk = false;
        }
        if (chk) {
            let formData = new FormData();
            formData.append('uid', auth._id);
            formData.append('basic_privacy', data.basic_privacy);
            formData.append('family_privacy', data.family_privacy);
            formData.append('astro_privacy', data.astro_privacy);
            formData.append('carrer_privacy', data.carrer_privacy);
            formData.append('spritual_privacy', data.spritual_privacy);
            formData.append('personality_privacy', data.personality_privacy);
            formData.append('photo_privacy', data.photo_privacy);
            formData.append('contact_privacy', data.contact_privacy);
            formData.append('activity_status', data.contact_privacy);
            formData.append('email_privacy', data.email_privacy);
            formData.append('mobile_privacy', data.mobile_privacy);
            if (data.two_factor_authentication) {
                formData.append('two_factor_authentication', data.two_factor_authentication);
            } else {
                formData.append('two_factor_authentication', false);

            }

            dispatch(SaveSetting(formData));


        } else {
            setFormErrors(validate(data));
        }
    }

    const validate = (values) => {
        let error = {};

        if (!values.basic_privacy) {
            error.basic_privacy = "This field is required!";
        } if (!values.family_privacy) {
            error.family_privacy = "This field is required!";
        }
        if (!values.astro_privacy) {
            error.astro_privacy = "This field is required!";
        }
        if (!values.carrer_privacy) {
            error.carrer_privacy = "This field is required!";
        }
        if (!values.spritual_privacy) {
            error.spritual_privacy = "This field is required!";
        }
        if (!values.personality_privacy) {
            error.personality_privacy = "This field is required!";
        }
        if (!values.photo_privacy) {
            error.photo_privacy = "This field is required!";
        }
        if (!values.contact_privacy) {
            error.contact_privacy = "This field is required!";
        }
        if (!values.activity_status) {
            error.activity_status = "This field is required!";
        }
        if (!values.email_privacy) {
            error.email_privacy = "This field is required!";
        }
        if (!values.mobile_privacy) {
            error.mobile_privacy = "This field is required!";
        }

        return error;
    }


    return (
        <>
            <section id="full_page_dashboard_withoutSidebar">
                <div class="container-fluid">
                    {/* <Sidebar data={element} sendToParent={childRes} disabled={sidebarDisabled} /> */}
                    <div class="upload_photos">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="box">
                                    <h2><img src="images/lock.png" alt="" />
                                        <span>Privacy</span>
                                        Setup Your Privacy
                                    </h2>
                                    <h3>Profile Details Visibility Preferences</h3>
                                    <div class="row">
                                        <div class="col-lg-6 pe-xl-5">
                                            <ul class="basic_list">

                                                <li>Basic Details <select aria-label="Default select example" onChange={handleChangeInput} name="basic_privacy" defaultValue="Everyone">

                                                    {privacyOptions.length > 0 && privacyOptions.map((item, i) => (
                                                        <option value={item} selected={data && data.basic_privacy === item}>{item}</option>
                                                    ))}
                                                </select>
                                                    {errors.basic_privacy && <p className={"error__feedback"}>{errors.basic_privacy}</p>}

                                                </li>

                                                <li>Family Details<select aria-label="Default select example" onChange={handleChangeInput} name="family_privacy">
                                                    <option selected={data && data.family_privacy === "Everyone"} value="Everyone">Everyone</option>
                                                    {privacyOptions.length > 0 && privacyOptions.map((item, i) => (
                                                        <option value={item} selected={data && data.family_privacy === item}>{item}</option>
                                                    ))}
                                                </select>
                                                    {errors.family_privacy && <p className={"error__feedback"}>{errors.family_privacy}</p>}
                                                </li>

                                                <li>Astro Details <select aria-label="Default select example" onChange={handleChangeInput} name="astro_privacy">
                                                    <option selected={data && data.astro_privacy === "My connection"} value="My connection">My connection</option>

                                                    {privacyOptions.length > 0 && privacyOptions.map((item, i) => (
                                                        <option value={item} selected={data && data.astro_privacy === item}>{item}</option>
                                                    ))}
                                                </select>
                                                    {errors.astro_privacy && <p className={"error__feedback"}>{errors.astro_privacy}</p>}
                                                </li>

                                                <li>Career Details <select aria-label="Default select example" onChange={handleChangeInput} name="carrer_privacy">
                                                    <option selected={data && data.carrer_privacy === "Everyone"} value="Everyone">Everyone</option>

                                                    {privacyOptions.length > 0 && privacyOptions.map((item, i) => (
                                                        <option value={item} selected={data && data.carrer_privacy === item}>{item}</option>
                                                    ))}
                                                </select>
                                                    {errors.carrer_privacy && <p className={"error__feedback"}>{errors.carrer_privacy}</p>}
                                                </li>

                                                <li>Spiritual Details <select aria-label="Default select example" onChange={handleChangeInput} name="spritual_privacy">
                                                    <option selected={data && data.spritual_privacy === "Everyone"} value="Everyone">Everyone</option>

                                                    {privacyOptions.length > 0 && privacyOptions.map((item, i) => (
                                                        <option value={item} selected={data && data.spritual_privacy === item}>{item}</option>
                                                    ))}
                                                </select>
                                                    {errors.spritual_privacy && <p className={"error__feedback"}>{errors.spritual_privacy}</p>}
                                                </li>

                                                <li>Personality Questions <select aria-label="Default select example" onChange={handleChangeInput} name="personality_privacy">
                                                    <option selected={data && data.personality_privacy === "Everyone"} value="Everyone">Everyone</option>

                                                    {privacyOptions.length > 0 && privacyOptions.map((item, i) => (
                                                        <option value={item} selected={data && data.personality_privacy === item}>{item}</option>
                                                    ))}
                                                </select>
                                                    {errors.personality_privacy && <p className={"error__feedback"}>{errors.personality_privacy}</p>}
                                                </li>


                                            </ul>
                                        </div>

                                        <div class="col-lg-6 ps-xl-5">
                                            <ul class="basic_list">

                                                <li>Photo Privacy <span>Who can see my Picture? </span><select aria-label="Default select example" onChange={handleChangeInput} name="photo_privacy">
                                                    <option selected={data && data.photo_privacy === "Everyone"} value="Everyone">Everyone</option>

                                                    {privacyOptions.length > 0 && privacyOptions.map((item, i) => (
                                                        <option value={item} selected={data && data.photo_privacy === item}>{item}</option>
                                                    ))}
                                                </select>
                                                    {errors.photo_privacy && <p className={"error__feedback"}>{errors.photo_privacy}</p>}
                                                </li>

                                                <li>Contact Privacy <span>Who send u a connection request? </span><select aria-label="Default select example" onChange={handleChangeInput} name="contact_privacy">
                                                    <option selected={data && data.contact_privacy === "Everyone"} value="Everyone">Everyone</option>

                                                    {privacyOptions.length > 0 && privacyOptions.map((item, i) => (
                                                        <option value={item} selected={data && data.contact_privacy === item}>{item}</option>
                                                    ))}
                                                </select>
                                                    {errors.contact_privacy && <p className={"error__feedback"}>{errors.contact_privacy}</p>}
                                                </li>

                                                <li>Activity Status <span>Who can see my activity, like my active status?</span> <select aria-label="Default select example" onChange={handleChangeInput} name="activity_status">
                                                    <option selected={data && data.activity_status === "Everyone"} value="Everyone">Everyone</option>
                                                    {privacyOptions.length > 0 && privacyOptions.map((item, i) => (
                                                        <option value={item} selected={data && data.activity_status === item}>{item}</option>
                                                    ))}
                                                </select>
                                                    {errors.activity_status && <p className={"error__feedback"}>{errors.activity_status}</p>}
                                                </li>

                                                <li>Email <span>Who can see my Email? </span> <select aria-label="Default select example" onChange={handleChangeInput} name="email_privacy">
                                                    <option selected={data && data.email_privacy === "My connection"} value="My connection">My connection</option>
                                                    {privacyOptions.length > 0 && privacyOptions.map((item, i) => (
                                                        <option value={item} selected={data && data.email_privacy === item}>{item}</option>
                                                    ))}
                                                </select>
                                                    {errors.email_privacy && <p className={"error__feedback"}>{errors.email_privacy}</p>}
                                                </li>
                                                <li>Mobile <span>Who can see my phone number? </span> <select aria-label="Default select example" onChange={handleChangeInput} name="mobile_privacy">
                                                    <option selected={data && data.mobile_privacy === "My connection"} value="My connection">My connection</option>
                                                    {privacyOptions.length > 0 && privacyOptions.map((item, i) => (
                                                        <option value={item} selected={data && data.mobile_privacy === item}>{item}</option>
                                                    ))}
                                                </select>
                                                    {errors.mobile_privacy && <p className={"error__feedback"}>{errors.mobile_privacy}</p>}
                                                </li>


                                                <li>Two-factor Authentication <span>Add extra layer of protection by two-factor authentication</span>
                                                    <div class="form-switch">
                                                        <input class="form-check-input" type="checkbox" name="two_factor_authentication" id="flexSwitchCheckChecked" onChange={handleChangeInput} checked={data && data.two_factor_authentication === true} />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-12 d-flex justify-content-between mt-5">
                                <Link to="/Dashboard" class="btn">Go to Dashboard</Link>
                                {/* <Link to="/MatchmakingSelection" class="btn skip">Skip</Link> */}
                                <a onClick={()=>skip()} class="btn skip">Skip</a>                    
                                {btnst === false ? <a onClick={handleSubmit} class="btn upload">Submit</a> :
                                    <a class="btn upload" onClick={() => Next()}>Next</a>

                                    // <Link to="/MatchmakingSelection" class="btn upload">Next</Link>

                                }

                            </div>
                            {msg !== "" && <p class="SucessColor">{msg}</p>}

                        </div>
                    </div>
                </div>
            </section>

        </>
    )

}