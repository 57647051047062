import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "../Sidebar";
import Header from "../Header";
import BodyColor from "../../../Layouts/BodyColor";
import { AcceptRejctRecomendation, GetProfileMatches, clacDash } from "../../../../Reducer/UserDashboardSlices/dashboardSlice";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Swal from 'sweetalert2';
import { API_BASE_URL } from "../../Data/content";
import Loader from "../../Loader";

export default function Recommended() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const [loading, setLoading] = useState(false);
    // const data = useSelector(state => state.dshbrd.data);
    const [data, setData] = useState([]);
    const success = useSelector(state => state.dshbrd.success);
    const error = useSelector(state => state.dshbrd.error);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(6);
    const nextPage = () => setPage(page + 1);
    const prevPage = () => setPage(page - 1);
    const handleChangePageSize = (newPageSize) => {
        setPage(1); // Reset page number when changing page size
        setPageSize(newPageSize);
    };

    useEffect(() => {
        let isMounted = true; // Flag to track whether the component is mounted

        // Fetch data function
        const fetchData = async () => {
            try {
                setLoading(true); // Set loading to true
                let dat = { uid: auth._id, type: "Recommended", page, pageSize };
                let res = await dispatch(GetProfileMatches(dat));
                if (isMounted && res && res.payload.data.data) {
                    setData(res.payload.data.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                if (isMounted) {
                    setLoading(false); // Set loading to false
                }
            }
        };

        fetchData(); // Call fetchData function

        // Cleanup function to handle component unmount
        return () => {
            isMounted = false; // Set isMounted to false when component is unmounted
        };

    }, [dispatch, auth._id, page, pageSize]);

    // async function getData() {
    //     let dat = { uid: auth._id, type: "Recommended", page, pageSize };
    //     dispatch(GetProfileMatches(dat));
    // }

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clacDash());
        } else {
            Swal.fire({
                title: 'Congratulation!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clacDash());
            // getData();

        }
    }
    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clacDash());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clacDash());
                break;
        }
    }

    const Approval = (type, id, gender) => {

        console.log('type', type, id, gender);
        if (gender === "Male") {
            let data = { girl_id: auth._id, type: type, boy_id: id, gender: "Male", loginId: auth._id };
            dispatch(AcceptRejctRecomendation(data));
        } else {
            let data = { boy_id: auth._id, type: type, girl_id: id, gender: "Female", loginId: auth._id };
            dispatch(AcceptRejctRecomendation(data));
        }
    }

    const goto = (id) => {
        navigate("/MyMatches/ViewProfile/" + id);
    }


    return (
        <>

            <BodyColor />
            {loading === true &&
                <Loader />
            }
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />
                    <Header />
                    <section id="match_dashboard" class="mt-4">
                        <div className="row">
                            {data && data.length > 0 ? data.map((item, i) => (

                                <div class="col-xl-4 col-sm-6 mb-3" >
                                    <div class="box">
                                        <div onClick={() => goto(item._id)}>
                                            <div id="carouselExampleIndicators2" className="carousel slide carousel-fade" data-bs-ride="carousel">
                                                <div className="carousel-indicators">
                                                    {item.photos && item.photos.length > 0 && item.photos.map((imageUrl, index) => (
                                                        <button
                                                            key={index}
                                                            type="button"
                                                            data-bs-target="#carouselExampleIndicators2"
                                                            data-bs-slide-to={index}
                                                            className={index === 0 ? 'active' : ''}
                                                            aria-current={index === 0 ? 'true' : 'false'}
                                                            aria-label={`Slide ${index + 1}`}
                                                        />
                                                    ))}
                                                </div>
                                                <div className="carousel-inner">
                                                    {item.photos && item.photos.length > 0 && item.photos.map((imageUrl, index) => (
                                                        <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} >

                                                            <img class="newcssforimg" src={imageUrl && API_BASE_URL + imageUrl} />
                                                        </div>

                                                    ))}
                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>

                                            <h2>{item.name}<img src="images/verify.png" alt="" /></h2>
                                            <h3>Profile Match %

                                                <div style={{ width: 40, height: 20 }}>
                                                    <CircularProgressbar value={item.OverAll} text={`${item.OverAll}%`}
                                                        styles={{
                                                            path: {
                                                                stroke: '#28A745', // You can replace '#FF6347' with 
                                                            },
                                                            text: {
                                                                fill: '#000000', // You can replace '#000000' with 
                                                            },
                                                        }} />
                                                </div>

                                            </h3>
                                            <h4><img src="images/k1.png" alt="" />Profile managed by Self</h4>
                                            <h4><img src="images/k2.png" alt="" />Mother Tongue is {item.Mother_tongue}</h4>
                                            <h4><img src="images/k3.png" alt="" />{item.city}, {item.country}</h4>
                                            <h4><img src="images/k4.png" alt="" />{item.income}</h4>
                                            <h4><img src="images/k5.png" alt="" />{item.Marital_status} , {Math.floor(item?.age)} years</h4>

                                        </div>

                                        <div class="d-flex justify-content-between">
                                            <a class="btn shortlist" onClick={() => Approval("Accepted", item._id, item.gender)}>Accept Recommendation</a>
                                            <a class="btn shortlist" onClick={() => Approval("Rejected", item._id, item.gender)}>Reject Recommendation</a>

                                        </div>


                                    </div>
                                </div>

                            )) : <p>No recommendations found.</p>}
                            <div className="common_paging">
                                <div className="d-flex">
                                    <button onClick={prevPage} disabled={page === 1}>Previous</button>
                                    <span>{page}</span>
                                    <button onClick={nextPage}>Next</button>
                                </div>
                                <select value={pageSize} onChange={(e) => handleChangePageSize(Number(e.target.value))}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>

                        </div>

                    </section>
                </div >
            </section >

        </>
    )

}