import { useState } from 'react';

export const useRequestStatus = () => {
  const [loadingIds, setLoadingIds] = useState([]);
  const [sortlistIds, setSortlistIds] = useState([]);

  const executeRequest = async (id, requestFunc) => {
    setLoadingIds(prev => [...prev, id]); // Add the current ID to the loading state
    try {
     await requestFunc();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingIds(prev => prev.filter(loadingId => loadingId !== id)); // Remove the ID from loading state
    }
  };

  const isLoading = (id) => loadingIds.includes(id); // Check if a specific ID is loading

  const executeRequestsortlist = async (id, requestFunc) => {
    setSortlistIds(prev => [...prev, id]); // Add the current ID to the loading state
    try {
     await requestFunc();
    } catch (error) {
      console.error(error);
    } finally {
        setSortlistIds(prev => prev.filter(loadingId => loadingId !== id)); // Remove the ID from loading state
    }
  };

  const isLoadingsortlist = (id) => sortlistIds.includes(id); 

  return { isLoading, executeRequest,executeRequestsortlist,isLoadingsortlist };
};
