import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import firebaseConfig from '../../../Firebase';
import { clearChatStatus, updateBlockStatus } from "../../../Reducer/chatSlice";
import Swal from 'sweetalert2';
import { BlockReasons } from "../Data/content";

export default function BlockUnblock({ data, setBlockPopup }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [reason, setReason] = useState("");
    const [report, setReport] = useState(false);
    const auth = useSelector(state => state.auth.auth);


    console.log('taaaa---------------->', data);

    // "block": false,
    // "block_by": null,
    // "userType": "User"

    useEffect(() => {

    }, []);

    const handleCloseModal = () => {
        setBlockPopup(false);
    }

    const handleInputChange = (e) => {
        setReason(e.target.value);
    }
    const handleInputChange1 = (e) => {
        if (e.target.checked === true) {
            setReport(true);
        } else {
            setReport(false);
        }
    }

    const confirmBlock = () => {
        if (reason === "") {
            alert("Please select reason!")
        } else {
            updateFirebase();
            updateDatabase()

        }
    }

    const updateDatabase = async () => {
        let dat = { block_by: auth._id, blocked_user: data._id, reason: reason, report: report, type: "block" };
        let res;
        debugger
        try {
            res= await dispatch(updateBlockStatus(dat));
           } catch (error) {
             console.error(error);
            
           } finally {
            if (res && res.payload.data.status === 'success') {
                // Only navigate if the status is 'success'
                navigate(-1);
            }
          
        }
        
    }

    const updateFirebase = async () => {
        var ref = firebaseConfig.database().ref(`MessagesList/${auth._id}/${data && data._id}`)
        await ref.once('value', function (snapshot) {
            if (snapshot.exists()) {
                ref.update({
                    block: true,
                    block_by: auth._id
                });
            }
        });

        var ref2 = firebaseConfig.database().ref(`MessagesList/${data && data._id}/${auth._id}`);
        await ref2.once('value', function (snapshot) {
            if (snapshot.exists()) {
                ref2.update({
                    block: true,
                    block_by: auth._id
                });
            }
        });

    }

    return (
        <>

            <div class="modal fade show block_popup" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true" style={{ display: "block" }}>
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel"><i class="fa-solid fa-ban"></i>Block {data && data.name}
                                <span>Are you sure you want to block {data && data.name}? </span>
                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleCloseModal()}></button>
                        </div>
                        <div class="modal-body">
                            <h2>Blocking prevents further communication and interaction with this user.</h2>
                            <h3>Reasons for Blocking</h3>

                            <ul>
                                {BlockReasons.length > 0 && BlockReasons.map((item, i) => (
                                    <li>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="block_reason"
                                                id="flexRadioDefault1" value={item} onChange={handleInputChange} />
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                {item}
                                            </label>
                                        </div>
                                    </li>
                                ))}

                            </ul>

                            <div class="form-check mt-3 mb-4">
                                <input class="form-check-input" type="checkbox" value="report" id="flexCheckDefault" onChange={handleInputChange1} />
                                <label class="form-check-label" for="flexCheckDefault">
                                    Report to VedicMarriage.ai
                                </label>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => handleCloseModal()}>Cancel</button>
                            <button type="button" class="btn btn-primary" onClick={() => confirmBlock()}><i class="fa-solid fa-ban me-2"></i>Confirm
                                Block</button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )


}
