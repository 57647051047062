import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateuserinfoFormSubmit } from '../api';


export const SubmitUiserUpdateinfoForm = createAsyncThunk('SubmitUiserUpdateinfoForm', async (formdata, { rejectWithValue }) => {
    try {
        const response = await UpdateuserinfoFormSubmit(formdata);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

const updateUserDataSlice = createSlice({
    name: 'UiserUpdateinfo',
    initialState: {
        success: false,
        error: null,
    },
    reducers: {
        clearUiserUpdateinfoForm: state => {
            state.success = false;
            state.error = null;

        },

    },
    extraReducers: builder => {
        builder
            .addCase(SubmitUiserUpdateinfoForm.pending, state => {
                state.success = true;
                state.error = null;
            })
            .addCase(SubmitUiserUpdateinfoForm.fulfilled, (state, action) => {
                state.success = action.payload;
                state.error = null;
            })
            .addCase(SubmitUiserUpdateinfoForm.rejected, (state, action) => {
                state.success = false;
                state.error = action.payload;
            })
    },
});


export const { clearUiserUpdateinfoForm } = updateUserDataSlice.actions;
export default updateUserDataSlice.reducer;