import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetProfileMatches, InterestSend, ShortListRemove, clacDash } from "../../../../Reducer/UserDashboardSlices/dashboardSlice";
import { API_BASE_URL } from "../../Data/content";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Swal from 'sweetalert2';
import Loader from "../../Loader";
import { useRequestStatus } from '../../../../hooks/useRequestStatus';
export default function Shortlisted() {
    const { isLoading, executeRequest,executeRequestsortlist,isLoadingsortlist } = useRequestStatus();
    const [locallySent, setLocallySent] = useState([]);
    const [locallySentsort, setLocallySentsort] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth.auth);
    const [loading, setLoading] = useState(false);
    // const data = useSelector(state => state.dshbrd.data);
    const [data, setData] = useState([]);
    const success = useSelector(state => state.dshbrd.success);
    const error = useSelector(state => state.dshbrd.error);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(6);
    const nextPage = () => setPage(page + 1);
    const prevPage = () => setPage(page - 1);
    const handleChangePageSize = (newPageSize) => {
        setPage(1); // Reset page number when changing page size
        setPageSize(newPageSize);
    };

    const get = () => {
        let isMounted = true; // Flag to track whether the component is mounted

        // Fetch data function
        const fetchData = async () => {
            try {
                setLoading(true); // Set loading to true
                let dat = { uid: auth._id, type: "ShortList", page, pageSize };
                let res = await dispatch(GetProfileMatches(dat));
                if (isMounted && res && res.payload.data.data) {
                    setData(res.payload.data.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                if (isMounted) {
                    setLoading(false); // Set loading to false
                }
            }
        };

        fetchData(); // Call fetchData function

        // Cleanup function to handle component unmount
        return () => {
            isMounted = false; // Set isMounted to false when component is unmounted
        };

    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clacDash());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clacDash());
                break;
        }
    }

    if (success && success.status === 200) {
        if (success.data.data) {
            dispatch(clacDash);
        } else {
            Swal.fire({
                title: 'Congratulation!',
                text: success.data.message,
                icon: 'success'
            });
            dispatch(clacDash());
            get();

        }
    }


    useEffect(() => {
        get();
    }, [dispatch, auth._id, page, pageSize]);




    const goto = (id) => {
        navigate("/MyMatches/ViewProfile/" + id);
    }

    const RemoveShortList = (id) => {
        let dat = { uid: auth._id, id: id };
        dispatch(ShortListRemove(dat));
    }

    const SendInterest = async(id) => {
        let data = { sent_by: auth._id, sent_to: id };
        // dispatch(InterestSend(data));
        await executeRequest(id,async () => {
            await dispatch(InterestSend(data));
            // Additional logic if needed after dispatch completes
            setLocallySent(prev => [...prev, id]);
          });
    }

    return (
        <>
            {loading === true &&
                <Loader />
            }
            <div class="row">

                {data && data.length > 0 ? data.map((item, i) => (
                    <div class="col-xl-4 col-sm-6 mb-3" >
                        <div class="box">
                            <div onClick={() => goto(item._id)}>
                                <div id="carouselExampleIndicators2" className="carousel slide carousel-fade" data-bs-ride="carousel">
                                    <div className="carousel-indicators">
                                        {item.photos && item.photos.length > 0 && item.photos.map((imageUrl, index) => (
                                            <button
                                                key={index}
                                                type="button"
                                                data-bs-target="#carouselExampleIndicators2"
                                                data-bs-slide-to={index}
                                                className={index === 0 ? 'active' : ''}
                                                aria-current={index === 0 ? 'true' : 'false'}
                                                aria-label={`Slide ${index + 1}`}
                                            />
                                        ))}
                                    </div>
                                    <div className="carousel-inner">
                                        {item.photos && item.photos.length > 0 && item.photos.map((imageUrl, index) => (
                                            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} >
                                                <img class="newcssforimg" src={imageUrl && API_BASE_URL + imageUrl} />
                                            </div>
                                        ))}
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                                <h2 >{item.name}<img src="images/verify.png" alt="" /></h2>
                                <h3>Profile Match %
                                    {/* <div class="circle_percent" data-percent="75"> */}
                                    <div style={{ width: 40, height: 20 }}>
                                        <CircularProgressbar value={item.OverAll} text={`${item.OverAll}%`}
                                            styles={{
                                                path: {
                                                    stroke: '#28A745', // You can replace '#FF6347' with 
                                                },
                                                text: {
                                                    fill: '#000000', // You can replace '#000000' with 
                                                },
                                            }} />
                                    </div>
                                    {/* <div class="circle_inner">
                        <div class="round_per"></div>
                    </div>
                </div> */}
                                </h3>
                                <h4><img src="images/k1.png" alt="" />Profile managed by Self</h4>
                                <h4><img src="images/k2.png" alt="" />Mother Tongue is {item.Mother_tongue}</h4>
                                <h4><img src="images/k3.png" alt="" />{item.city}, {item.country}</h4>
                                <h4><img src="images/k4.png" alt="" />{item.income}</h4>
                                <h4><img src="images/k5.png" alt="" />{item.Marital_status} , {Math.floor(item?.age)} years</h4>
                            </div>

                            <div class="d-flex justify-content-between">
                                {item.InterestSent === true ? <a class="btn" >Interest Sent</a> :
                                //  <a class="btn" onClick={() => SendInterest(item._id)}>Send Interest</a>
                                <a
                                // key={item._id}
                                className={`btn ${isLoading(item._id) || locallySent.includes(item._id) ? 'disabled' : ''}`} 
                                onClick={() => SendInterest(item._id)} 
                                disabled={isLoading(item._id) || locallySent.includes(item._id)} // Disable only the button that is currently loading
                              >
                                {locallySent.includes(item._id)
                                        ? 'Interest Sent' // Show 'Interest Sent' for locally marked items
                                        : isLoading(item._id)
                                        ? 'Sending...' // Show 'Sending...' while the API is in progress
                                        : 'Send Interest' // Default state
                                    }
                              </a>
                                 }
                                <a class="btn shortlist" onClick={() => RemoveShortList(item._id)}>Remove</a>
                            </div>
                        </div>
                    </div>
                )) : <p>No matches found.</p>}


                <div className="common_paging">
                    <div className="d-flex">
                        <button onClick={prevPage} disabled={page === 1}>Previous</button>
                        <span>{page}</span>
                        <button onClick={nextPage}>Next</button>
                    </div>
                    <select value={pageSize} onChange={(e) => handleChangePageSize(Number(e.target.value))}>
                        <option value={6}>6</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>

            </div>
        </>
    )

}