import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Nakshtra, Relgion, CommunityName, Gotra, rashiNames } from "../../Data/content";
import Swal from 'sweetalert2';
import { clearStateAstro, SaveAstro, GetAstroInfo } from "../../../../Reducer/StepForms/astroSlice";
import { clearUiserUpdateinfoForm,SubmitUiserUpdateinfoForm } from "../../../../Reducer/FrontendForms/updateUserDataSlice";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-google-places-autocomplete';


export default function Astro() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const error = useSelector(state => state.astro.error);
    const success = useSelector(state => state.astro.success);
    const auth = useSelector(state => state.auth.auth);
    const [errors, setFormErrors] = useState({});
    const [astroo, setAstroo] = useState({});
    const [location, setLocation] = useState(null);
    const [lat, setLat] = useState("");
    const [long, setLong] = useState("");
    const [timeZone, setTimeZone] = useState("");
    const [dobSelected, setDobSelected] = useState(false);
    const [amPmSelected, setAmPmselected] = useState(false);
    const [msg, setMsg] = useState("");
    const [submitType, setSubmitType] = useState("");

    const data = useSelector(state => state.user.data);

    // const Community = CommunityName.sort();
    const navigationn = () => {
        setMsg("");
        let name = "Family";
        navigate("/UserStepForms", { state: { name } });
    }

    if (success && success.status === 200) {
        if (success.data.data) {

            dispatch(clearStateAstro());
        } else {
            if (submitType === "draft") {
                setMsg(success.data.message);
                dispatch(clearStateAstro());
            } else {
                dispatch(clearStateAstro());
                navigationn();
            }


        }
    }



    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStateAstro());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStateAstro());
                break;
        };
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setMsg("");
        let ampm = "";
        if (astroo.time) {
            const timeValue = astroo.time;
            const hour = parseInt(timeValue.split(':')[0]);
            ampm = hour >= 12 ? 'PM' : 'AM';
        }

        let formData = new FormData();
        formData.append('uid', auth._id);
        formData.append('dob', astroo && astroo.dob ? astroo.dob : "");
        formData.append('time', astroo && astroo.time ? astroo.time : "");
        formData.append('ampm', ampm);
        formData.append('birth_place', location && location.label ? location.label : "");
        formData.append('latitude', lat ? lat : "");
        formData.append('longitude', long ? long : "");
        formData.append('timeZone', timeZone ? timeZone : "");
        formData.append('nakshtra', astroo && astroo.nakshtra ? astroo.nakshtra : "Don't know");
        formData.append('manglik', astroo && astroo.manglik ? astroo.manglik : "Yes");
        formData.append('rashi', astroo && astroo.rashi ? astroo.rashi : "Aries");
        formData.append('religion', astroo && astroo.religion ? astroo.religion : "Hindu");
        // formData.append('community', astroo && astroo.community ? astroo.community : "Don't know");
        formData.append('gotra', astroo && astroo.gotra ? astroo.gotra : "Don't know");
        formData.append('caste', astroo && astroo.caste ? astroo.caste : "");
        if (e.nativeEvent.submitter.name === 'draft') {
            setSubmitType('draft');
            formData.append('draft', 'true');

            dispatch(SaveAstro(formData));

        } else {

            let values = validate(astroo);
            let chk = true;
            if (Object.keys(values).length > 0) {
                chk = false;
            }
            if (chk) {
                setSubmitType('next');
                formData.append('draft', 'false');
                formData.append('saved', true);
                dispatch(SaveAstro(formData));

            } else {
                setFormErrors(validate(astroo));
            }
        }

    };

    const validate = (values) => {
        let error = {};

        console.log('locatin', values);

        if (!values.dob) {
            error.dob = "This field is required!";
        } if (values.dob) {
            const dob = new Date(values.dob);
            const eighteenYearsAgo = new Date();
            eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

            const maxAgeDate = new Date();
            maxAgeDate.setFullYear(maxAgeDate.getFullYear() - 80);

            if (dob >= eighteenYearsAgo) {
                error.dob = "Age must be at least 18 years old!";
            } else if (dob <= maxAgeDate) {
                error.dob = "Age must be less than 80 years old!";
            }
        }
        if (!values.time) {
            error.time = "This field is required!";
        }
        if (!location) {
            error.birth_place = "This field is required!";
        }
        if (location && location.value && location.value == "") {
            error.birth_place = "This field is required!";
        }
        if (!values.nakshtra || (values.nakshtra.trim() === "" && values.nakshtra !== "Don't know")) {
            errors.nakshtra = "This field is required!";
        }
        const validManglikValues = ["Yes", "No", "I don't know"];
        if (!values.manglik || !validManglikValues.includes(values.manglik)) {
            errors.manglik = "This field is required!";
        }
        // Rashi validation
        if (!values.rashi || (values.rashi.trim() === "" && values.rashi !== "Aries")) {
            errors.rashi = "This field is required!";
        }
        if (!values.religion || (values.religion.trim() === "" && values.religion !== "Hindu")) {
            errors.religion = "This field is required!";
        }
        // Community validation
        // if (!values.community || (values.community.trim() === "" && values.community !== "Don't know")) {
        //     errors.community = "This field is required!";
        // }
        // if (!values.gotra) {
        //     error.gotra = "This field is required!";
        // }
        // if (!values.caste) {
        //     error.caste = "This field is required!";
        // }

        return error;
    }

    const handleChangeInput = (e) => {
        if (e.target.name === "dob" && data && data.profile_status === "Approved") {
            let formData = new FormData();
            formData.append('uid', auth._id);
            formData.append('dob', e.target.value);
           
            dispatch(SubmitUiserUpdateinfoForm(formData));
            alert("This edit needs admin approval");
        } 
        // else if (e.target.name === "time" && data && data.profile_status === "Approved") {
        //     alert("This edit needs admin approval");
        // }
        else{
            const value = e.target.value;
            setAstroo({
                ...astroo,
                [e.target.name]: value
            });
        }
       
    }


    useEffect(() => {
        getData();
    }, [data]);

    async function getData() {
        let dat = { uid: auth._id };
        let res = await dispatch(GetAstroInfo(dat));
        if (res.payload && res.payload.data.data) {
            if (data && data.profile_status == "Approved") {
                setDobSelected(!!res.payload.data.data.dob);
                setAmPmselected(!!res.payload.data.data.ampm)
                // setNameSelected(!!res.payload.data.data.name);
                // setMotherToungeSelected(!!res.payload.data.data.Mother_tongue);
            }

            setAstroo(res.payload.data.data);
            if (res.payload.data.data.birth_place) {

                console.log('res.payload.data.data.birth_place', res.payload.data.data.birth_place);
                setLocation({
                    label: res.payload.data.data.birth_place,
                    value: ""
                });
            }
        }
    }


    const handleSelect = async (value) => {

        try {
            const results = await geocodeByAddress(value.label);
            const latLng = await getLatLng(results[0]);
            if (data && data.profile_status === "Approved") {
                // alert("This Can't Change");
                let formData = new FormData();
                formData.append('birth_place', value.label ? value.label : "");
                formData.append('latitude', latLng.lat ? latLng.lat : "");
                formData.append('longitude', latLng.lng ? latLng.lng : "");
                formData.append('uid', auth._id);

                
               
                dispatch(SubmitUiserUpdateinfoForm(formData));
                alert("This edit needs admin approval");
            }
            else {

            


            setLocation({
                value: value.value,
                label: value.label
            });
            setLat(latLng.lat);
            setLong(latLng.lng);

            console.log('value.label', value);
            // Get timezone
            let timeZone = await getTimezone(latLng.lat, latLng.lng);
            setTimeZone(timeZone);

            console.log('timeZone', timeZone);
            }

        } catch (error) {
            console.error('Error selecting place: ', error);
        }
    };

    async function getTimezone(latitude, longitude) {
        const timestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
        const apiKey = 'AIzaSyBaw2eolMvHQq6Wkzh4HT77AnIg2Ud6ejc'; // Replace 'YOUR_API_KEY' with your actual Google API key

        const response = await fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${apiKey}`);
        const data = await response.json();

        if (data.status === 'OK') {
            const timezoneOffsetSeconds = data.rawOffset + data.dstOffset; // Total offset from UTC in seconds
            const timezoneOffsetHours = timezoneOffsetSeconds / 3600; // Convert to hours
            console.log('Timezone offset:', timezoneOffsetHours);
            return timezoneOffsetHours;
            // const timezoneId = data.timeZoneId;
            // console.log('Timezone:', timezoneId);
            // return timezoneId;
        } else {
            console.error('Failed to fetch timezone:', data.status);
            return null;
        }
    }

    // function getMinDate() {
    //     const today = new Date();
    //     const minDate = new Date(today.getFullYear() - 60, today.getMonth(), today.getDate()); // 60 years ago from today
    //     return minDate.toISOString().split('T')[0]; // Format the date as "YYYY-MM-DD"
    // }
    const handleChangeInputauto = (selected) => {
        
        const value = selected.length > 0 ? selected[0] : '';
        setAstroo({
            ...astroo,
            gotra: value,
        });
       
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div class="tab">
                    <h3>Astro Details</h3>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">Date of Birth*</label>
                            <div class="row">
                                <div class="col-12">
                                    <input type="date" class="form-control" placeholder="" defaultValue={astroo && astroo.dob} name="dob" onChange={handleChangeInput} />
                                    {/* disabled={dobSelected} */}
                                    {errors.dob && <p className={"error__feedback"}>{errors.dob}</p>}
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <label class="form-label">Time of Birth*</label>
                            <div class="row">
                                <div class="col-12">
                                    <input type="time" class="form-control" placeholder="" defaultValue={astroo && astroo.time} name="time" onChange={handleChangeInput} />
                                    {/* disabled={amPmSelected} */}
                                    {errors.time && <p className={"error__feedback"}>{errors.time}</p>}

                                </div>

                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Place Of Birth</label>
                                <PlacesAutocomplete
                                    selectProps={{
                                        value: location,
                                        onChange: handleSelect,
                                    }}
                                    query={{ key: "AIzaSyBaw2eolMvHQq6Wkzh4HT77AnIg2Ud6ejc" }}
                                    fetchDetails={true}
                                    onFail={error => console.log(error)}
                                />
                                <input name="birth_place" className="service-input" type="hidden" placeholder="Enter address" value={location} />
                                {errors.birth_place && <p className={"error__feedback"}>{errors.birth_place}</p>}

                                {/* <input type="text" class="form-control" defaultValue={astroo && astroo.birth_place} name="birth_place" onChange={handleChangeInput} />

                                {errors.birth_place && <p className={"error__feedback"}>{errors.birth_place}</p>} */}

                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Nakshtra*</label>
                                <select class="form-select" aria-label="Default select example" name="nakshtra" onChange={handleChangeInput}>

                                    {Nakshtra.length > 0 && Nakshtra.map((item, i) => (
                                        <option selected={astroo && astroo.nakshtra === item} value={item}>{item}</option>
                                    ))}
                                </select>
                                {errors.nakshtra && <p className={"error__feedback"}>{errors.nakshtra}</p>}
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Manglik*</label>
                                        <select class="form-select" aria-label="Default select example" name="manglik" onChange={handleChangeInput}>
                                            {/* <option selected disabled>Select</option> */}
                                            <option selected={astroo && astroo.manglik === "Yes"} value="Yes">Yes</option>
                                            <option selected={astroo && astroo.manglik === "No"} value="No">No</option>
                                            <option selected={astroo && astroo.manglik === "I dont know"} value="I dont know">I dont know</option>
                                        </select>
                                        {errors.manglik && <p className={"error__feedback"}>{errors.manglik}</p>}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Rashi*</label>
                                        <select class="form-select" aria-label="Default select example" name="rashi" onChange={handleChangeInput}>
                                            {/* <option selected disabled>Select</option> */}
                                            {rashiNames.length > 0 && rashiNames.map((item, i) => (
                                                <option selected={astroo && astroo.rashi === item} value={item}>{item}</option>
                                            ))}
                                        </select>
                                        {errors.rashi && <p className={"error__feedback"}>{errors.rashi}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <h3>Religious Background</h3>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Religion*</label>
                                <select class="form-select" aria-label="Default select example" name="religion" onChange={handleChangeInput}>
                                    {/* <option selected disabled>Select</option> */}
                                    {Relgion.length > 0 && Relgion.map((rel, i) => (
                                        <option selected={astroo && astroo.religion === rel} value={rel}>{rel}</option>
                                    ))}
                                </select>
                                {errors.religion && <p className={"error__feedback"}>{errors.religion}</p>}
                            </div>
                        </div>

                        {/* <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Community*</label>
                                <select class="form-select" aria-label="Default select example" name="community" onChange={handleChangeInput}>
                                
                                    {CommunityName.length > 0 && CommunityName.map((com, i) => (
                                        <option selected={astroo && astroo.community === com} value={com}>{com}</option>

                                    ))}

                                </select>
                                {errors.community && <p className={"error__feedback"}>{errors.community}</p>}
                            </div>
                        </div> */}

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Gothra / Gothram</label>
                                <Typeahead
                                        id="basic-example"
                                        onChange={handleChangeInputauto}
                                        options={Gotra}
                                         name="occupation"
                                        placeholder="Choose a Occupation"
                                        selected={astroo.gotra ? [astroo.gotra] : []}
                                        />
                                {/* <select class="form-select" aria-label="Default select example" name="gotra" onChange={handleChangeInput}>
                                   
                                    {Gotra.length > 0 && Gotra.map((tem, i) => (
                                        <option selected={astroo && astroo.gotra === tem} value={tem}>{tem}</option>
                                    ))}
                                </select> */}
                                {errors.gotra && <p className={"error__feedback"}>{errors.gotra}</p>}
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-label">Caste</label>
                                <input type="text" class="form-control" defaultValue={astroo && astroo.caste} onChange={handleChangeInput} name="caste" spellCheck={true} />
                                {errors.caste && <p className={"error__feedback"}>{errors.caste}</p>}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step_common_btn">
                    <div class="row">
                        <div class="col-sm-6" >
                            <button class="btn two" type="submit" name="draft" disabled={astroo && astroo.saved && astroo.saved === true}>Save Draft</button>
                        </div>

                        <div class="col-sm-6 text-end">
                            <a class="btn" onClick={() => navigationn()}>Skip</a>
                            <button class="btn" type="submit"
                            >Next</button>
                        </div>
                        {msg !== "" && <p class="SucessColor">{msg}</p>}


                    </div>

                </div>
            </form>

        </>
    )

}